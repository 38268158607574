import { Controller } from 'stimulus'

class TypesController extends Controller {}

export default class extends (Controller as typeof TypesController) {
  connect() {
    this.element.setAttribute('novalidate', 'true')
    this.element.addEventListener('blur', this.onBlur, true)
    this.element.addEventListener('submit', this.onSubmit)
    this.element.addEventListener('ajax:beforeSend', this.onSubmit)
  }

  disconnect() {
    this.element.removeEventListener('blur', this.onBlur)
    this.element.removeEventListener('submit', this.onSubmit)
    this.element.removeEventListener('ajax:beforeSend', this.onSubmit)
  }

  onBlur = (event: Event) => {
    this.validateField(event.target as HTMLFormElement)
  }

  onSubmit = (event: Event) => {
    if (!this.validateForm()) {
      event.preventDefault()
      this.firstInvalidField?.focus()
    } else {
      event.submitter.disabled = true
    }
  }

  validateForm() {
    let isValid = true
    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField(field: HTMLFormElement) {
    if ((field.tagName !== 'SELECT' && field.tagName !== 'INPUT') || !this.shouldValidateField(field)) return true
    const isValid = field.checkValidity()
    field.classList.toggle('invalid', !isValid)
    this.refreshErrorForInvalidField(field, isValid)
    return isValid
  }

  shouldValidateField(field: HTMLFormElement) {
    return !field.disabled && !['file', 'reset', 'submit', 'button'].includes(field.type)
  }

  refreshErrorForInvalidField(field: HTMLFormElement, isValid: boolean) {
    this.removeExistingErrorMessage(field)
    if (!isValid) this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage(field: HTMLFormElement) {
    const fieldContainer = field.closest('.field')
    if (!fieldContainer) return
    const existingErrorMessageElement = fieldContainer.querySelector('.error')
    if (existingErrorMessageElement) existingErrorMessageElement.parentNode?.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField(field: HTMLFormElement) {
    if (['radio', 'checkbox'].includes(field.type)) {
      field.closest('.field')?.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
    } else {
      field.insertAdjacentHTML('afterend', this.buildFieldErrorHtml(field))
    }
  }

  buildFieldErrorHtml(field: HTMLFormElement) {
    return `<p class="my-1 text-red-500 error">${field.validationMessage}</p>`
  }

  get formFields(): HTMLFormElement[] {
    return Array.from((this.element as HTMLFormElement).elements) as HTMLFormElement[]
  }

  get firstInvalidField() {
    return this.formFields.find((field) => !field.checkValidity())
  }
}
